import { FilterOutlined, HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Checkbox, Col, Divider, Input, InputNumber, Row, Slider, Space, Typography } from "antd";
import Drawer from "antd/es/drawer";
import { find, map } from "lodash";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ProductList from "../components/ProductList/ProductList";
import { getAllBrand } from "../services/brandServices";
import { getAllCategory } from "../services/categoryServices";
import { formatterNumber, parserNumber } from "../utils";

const SearchPage = () => {
  const { category, brand, country, keyword } = useParams();

  const [params, setParams] = useState({ category, brand, country, keyword });

  const [inputValue, setInputValue] = useState([0, 5000000]);

  const [filterParams, setFilterParams] = useState({
    keyword: null,
    category: null,
    brand: null,
    fromCost: null,
    toCost: null,
  });

  const { data: categoryList, isFetching: fetchingCategory } = useQuery({
    queryKey: "getAllCategories",
    queryFn: getAllCategory,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: brandList, isFetching: fetchingBrand } = useQuery({
    queryKey: "getALlBrands",
    queryFn: getAllBrand,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const [showMobileFilter, setShowMobileFilter] = useState(false);

  const [searchValue, setSearchValue] = useState(keyword || "");

  const handleChangePriceRange = useCallback((value) => {
    setInputValue(value);
    setFilterParams((prev) => ({ ...prev, fromCost: value?.[0], toCost: value?.[1] }));
  }, []);

  const handleChangeCategory = useCallback((value) => {
    setFilterParams((prev) => ({ ...prev, category: value }));
  }, []);

  const handleChangeBrand = useCallback((value) => {
    setFilterParams((prev) => ({ ...prev, brand: value }));
  }, []);

  const handleOnSubmit = useCallback(() => setParams(filterParams), [filterParams]);

  const categories = useMemo(() => {
    if (!fetchingCategory && categoryList && categoryList?.length > 0) {
      const categories = map(categoryList, (item) => ({ label: item?.name, value: item?.slug }));
      categories.unshift({ label: "Tất cả", value: "all" });
      return categories;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingCategory, categoryList]);

  const brands = useMemo(() => {
    if (!fetchingBrand && brandList && brandList?.length > 0) {
      const brands = map(brandList, (item) => ({ label: item?.name, value: item?.name }));
      brands.unshift({ label: "Tất cả", value: "all" });
      return brands;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingBrand, categoryList]);

  useEffect(() => {
    if (category) {
      document.title = find(categories, (item) => item?.value === category)?.label;
    }
    if (brand) {
      document.title = find(brands, (item) => item?.value === brand)?.label;
    }
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [category, brand, categories, brands]);

  const handleSearch = (value) => {
    setSearchValue(value);
    setParams(prev => ({
      ...prev,
      keyword: value
    }));
  };

  return (
    <Fragment>
      <Breadcrumb
        className='wide mx-auto mb-4 px-4 md:px-6 lg:px-0'
        items={[
          {
            href: "/",
            title: (
              <>
                <HomeOutlined />
                <span>Trang chủ</span>
              </>
            ),
          },
          {
            ...(category
              ? {
                  href: `/danh-muc/${find(categories, (item) => item?.value === category)?.value}`,
                  title: <span>Danh mục</span>,
                }
              : brand
              ? {
                  href: `/thuong-hieu/${find(brands, (item) => item?.value === brand)?.value}`,
                  title: <span>Thương hiệu</span>,
                }
              : country
              ? {
                  href: "/nuoc-san-xuat",
                  title: "Nước sản xuất",
                }
              : { title: "Tìm kiếm" }),
          },
          {
            ...(category
              ? {
                  title: find(categories, (item) => item?.value === category)?.label,
                }
              : brand
              ? {
                  title: find(brands, (item) => item?.value === brand)?.label,
                }
              : country
              ? {
                  title: country,
                }
              : { title: "Tất cả sản phẩm" }),
          },
        ]}
      />

      <div className='wide mx-auto relative px-4 md:px-6 lg:px-0'>
        {/* Mobile/Tablet Filter Header */}
        <div className='w-full flex items-center gap-3 mb-4 lg:hidden'>
          <Button
            onClick={() => setShowMobileFilter(true)}
            icon={<FilterOutlined />}
            className='min-w-[100px]'
          >
            Bộ lọc
          </Button>
          <Input.Search
            placeholder="Tìm kiếm sản phẩm"
            onSearch={handleSearch}
            className='flex-1'
            allowClear
            defaultValue={searchValue}
          />
        </div>

        <div className='flex gap-6'>
          {/* Desktop Sider */}
          <aside className='hidden lg:block w-[280px] flex-shrink-0'>
            <div className='sticky top-4 bg-white rounded-lg p-4'>
              <Typography className='mb-2 font-semibold'>Tên sản phẩm</Typography>
              <Input
                autoComplete='true'
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                spellCheck={false}
                placeholder='Tên sản phẩm, thương hiệu, mô tả'
                allowClear
                className='mb-2'
              />
              <Button
                className='w-full min-h-[35px] flex items-center justify-center'
                type='primary'
                icon={<SearchOutlined />}
                onClick={() => handleSearch(searchValue)}
              >
                Tìm kiếm
              </Button>
              <Divider className='my-3' type='dashed' />

              <Typography className='mb-2 font-semibold'>Thương hiệu</Typography>
              <Checkbox.Group onChange={handleChangeBrand}>
                <Space direction='vertical'>
                  {map(brands, (item) => (
                    <Checkbox value={item?.value}>{item?.label}</Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
              <Divider className='my-3' type='dashed' />

              <Typography className='mb-2 font-semibold'>Danh mục</Typography>
              <Checkbox.Group onChange={handleChangeCategory}>
                <Space direction='vertical'>
                  {map(categories, (item) => (
                    <Checkbox value={item?.value}>{item?.label}</Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
              <Divider className='my-3' type='dashed' />

              <Typography className='mb-2 font-semibold'>Khoảng giá</Typography>
              <Slider
                range
                min={0}
                max={5000000}
                step={100000}
                onChange={handleChangePriceRange}
                value={inputValue}
                tooltip={{
                  formatter: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
                }}
              />
              <Row gutter={[12, 12]} className='mt-4'>
                <Col span={12}>
                  <InputNumber
                    placeholder='Tối thiểu'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    value={inputValue?.[0]}
                    suffix='đ'
                    className='w-full'
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    placeholder='Tối đa'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    value={inputValue?.[1]}
                    suffix='đ'
                    className='w-full'
                  />
                </Col>
              </Row>
              <Button
                className='w-full mt-4'
                type='primary'
                onClick={handleOnSubmit}
              >
                Áp dụng
              </Button>
            </div>
          </aside>

          {/* Product List Area */}
          <div className='w-full lg:flex-1'>
            <ProductList params={params} page={1} size={24} placement='search' />
          </div>
        </div>

        {/* Mobile/Tablet Filter Drawer */}
        <Drawer
          title="Bộ lọc sản phẩm"
          placement="left"
          onClose={() => setShowMobileFilter(false)}
          open={showMobileFilter}
          width={window.innerWidth < 768 ? '85%' : '50%'}
          className='lg:hidden'
        >
          <div className='flex flex-col gap-5'>
            <div>
              <Typography className='mb-3 font-semibold'>Tên sản phẩm</Typography>
              <Input
                autoComplete='true'
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                placeholder='Tên sản phẩm, thương hiệu, mô tả'
                allowClear
                className='mb-3'
              />
              <Button
                className='w-full'
                type='primary'
                onClick={() => {
                  handleSearch(searchValue);
                  setShowMobileFilter(false);
                }}
              >
                Tìm kiếm
              </Button>
            </div>

            <div>
              <Typography className='mb-3 font-semibold'>Thương hiệu</Typography>
              <Checkbox.Group onChange={handleChangeBrand} className='flex flex-col gap-2'>
                {map(brands, (item) => (
                  <Checkbox value={item?.value}>{item?.label}</Checkbox>
                ))}
              </Checkbox.Group>
            </div>

            <div>
              <Typography className='mb-3 font-semibold'>Danh mục</Typography>
              <Checkbox.Group onChange={handleChangeCategory} className='flex flex-col gap-2'>
                {map(categories, (item) => (
                  <Checkbox value={item?.value}>{item?.label}</Checkbox>
                ))}
              </Checkbox.Group>
            </div>

            <div>
              <Typography className='mb-3 font-semibold'>Khoảng giá</Typography>
              <Slider
                range
                min={0}
                max={5000000}
                step={100000}
                onChange={handleChangePriceRange}
                value={inputValue}
                tooltip={{
                  formatter: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
                }}
              />
              <Row gutter={[12, 12]} className='mt-4'>
                <Col span={12}>
                  <InputNumber
                    placeholder='Tối thiểu'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    value={inputValue?.[0]}
                    suffix='đ'
                    className='w-full'
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    placeholder='Tối đa'
                    formatter={formatterNumber}
                    parser={parserNumber}
                    value={inputValue?.[1]}
                    suffix='đ'
                    className='w-full'
                  />
                </Col>
              </Row>
            </div>

            <Button
              type='primary'
              className='mt-4'
              onClick={() => {
                handleOnSubmit();
                setShowMobileFilter(false);
              }}
            >
              Áp dụng bộ lọc
            </Button>
          </div>
        </Drawer>
      </div>
    </Fragment>
  );
};

export default SearchPage;
