import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import {
  AppstoreOutlined,
  CaretDownFilled,
  CaretDownOutlined,
  CommentOutlined,
  HistoryOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Divider, Dropdown, FloatButton, Image, Input, Space, Spin, Tag, Tooltip, Typography, notification } from "antd";
import { debounce, find, isEmpty, map, reduce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Register from "../Register";

import { NumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import TopBanner from "../../components/TopBanner";
import { setShowLoginModal } from "../../redux/reducer/modalReducer";
import { removeAllRecentKeyword, setRecentKeyword } from "../../redux/reducer/recentKeywordReducer";
import { getAllCategory } from "../../services/categoryServices";
import { findProduct } from "../../services/productServices";
import * as userServices from "../../services/userServices";
import Login from "../Login";
import Cart from "./Cart";
import Hotline from "./Hotline";
import Logo from "./Logo";
import MenuDropdown from "./MenuDropdown";

const Header = () => {
  const navigate = useNavigate();

  const { cart } = useSelector((state) => state?.cartReducer);

  const { banners } = useSelector((state) => state?.imageReducer);

  const { recentKeywords } = useSelector((state) => state?.recentKeywordReducer);

  // const { categories } = useSelector((state) => state?.categoryReducer);

  const [keyword, setKeyword] = useState("");

  const [products, setProducts] = useState([]);

  const [searching, setSearching] = useState(false);

  const { isShowRegisterModal, isShowLoginModal } = useSelector((state) => state?.modalReducer);

  const { currentUser } = useSelector((state) => state.authReducer?.login);

  const dispatch = useDispatch();

  const { data: categories } = useQuery({ queryKey: "getAllCategory", queryFn: getAllCategory, enabled: true, refetchOnWindowFocus: false });

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (value) => {
      setSearching(true);
      await findProduct({ params: { keyword: value, limits: 5 } })
        .then((res) => setProducts(res))
        .catch((err) => notification.error({ message: "Có lỗi xảy ra, vui lòng nhập lại!" }))
        .finally(() => setSearching(false));
    }, 500),
    []
  );

  const handleOnSearch = useCallback((value) => {
    if (!value.trim()) {
      setProducts([]);
      return;
    }
    debouncedSearch(value);
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLogout = useCallback(async () => {
    await userServices
      .logoutUser(null, dispatch)
      .then((res) => notification.success({ description: res?.response?.data?.message || "Đăng xuất thành công!", placement: "top" }))
      .finally(() => navigate("/"));
  });

  const cartCounterElement = useMemo(
    () => (
      <div className='relative mt-[2px]'>
        <Badge
          count={reduce(
            cart,
            (acc, cur) => {
              return (acc += cur?.quantity);
            },
            0
          )}
        >
          <ShoppingOutlined className='text-[22px] text-white cursor-pointer hover:opacity-80' />
        </Badge>
      </div>
    ),
    [cart]
  );

  const categoryElement = useMemo(
    () => (
      <Tag color='white' className='px-3 py-[2px] rounded-sm group hidden lg:block'>
        <Space className='cursor-pointer text-primary flex items-center rounded-lg'>
          <i className='bi bi-list text-primary'></i>
          <Typography className='text-primary font-semibold'>DANH MỤC</Typography>
          <CaretDownOutlined />
        </Space>
      </Tag>
    ),
    []
  );

  const userElementDropdown = useMemo(
    () => (
      <div className='bg-white rounded-lg p-3 shadow-lg flex flex-col gap-3'>
        <Link to='/account' className='flex gap-2 text-black hover:bg-primary hover:text-white py-2 px-3 rounded-lg cursor-pointer transition'>
          <i className='bi bi-person-lines-fill'></i>
          <Typography className='text-inherit'>Thông tin cá nhân</Typography>
        </Link>
        {(currentUser?.role === "admin" || currentUser?.role === "employee") && (
          <Link
            to='/dashboard'
            target='_blank'
            className='flex gap-2 text-black hover:bg-primary hover:text-white py-2 px-3 rounded-lg cursor-pointer transition'
          >
            <i className='bi bi-speedometer'></i>
            <Typography className='text-inherit'>Trang quản lý</Typography>
          </Link>
        )}
        <Link to='/orders' className='flex gap-2 text-black hover:bg-primary hover:text-white py-2 px-3 rounded-lg cursor-pointer transition'>
          <i className='bi bi-box-seam-fill'></i>
          <Typography className='text-inherit'>Lịch sử đơn hàng</Typography>
        </Link>
        <div onClick={() => handleLogout()} className='flex gap-2 text-black hover:bg-primary hover:text-white py-2 px-3 rounded-lg cursor-pointer transition'>
          <i className='bi bi-box-arrow-right'></i>
          <Typography className='text-inherit'>Đăng xuất</Typography>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const userElement = useMemo(
    () => (
      <div className='flex bg-white items-center rounded-full hover:bg-opacity-90 cursor-pointer py-1'>
        {currentUser ? (
          <div className='flex items-center px-2'>
            <Avatar src={currentUser?.avatar} className='mr-2' icon={<UserOutlined />} shape='circle'>
              {currentUser?.fullname || currentUser?.username}
            </Avatar>
            <Typography className='text-primary font-semibold text-xs'>
              {currentUser?.fullname || currentUser?.username}
              <CaretDownFilled className='ml-2' />
            </Typography>
          </div>
        ) : (
          <div className='flex items-center py-2 px-3' onClick={() => dispatch(setShowLoginModal(true))}>
            <UserOutlined className='text-primary mb-[2px] mr-[6px] text-[16px]' />
            <Typography className='text-primary  font-semibold text-xs'>Đăng nhập / Đăng ký</Typography>
          </div>
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const searchElement = useMemo(
    () => (
      <div className='flex w-full flex-col gap-2'>
        <Input
          spellCheck={false}
          onChange={(e) => handleOnSearch(e?.target?.value)}
          onBlur={(e) => setKeyword(e?.target?.value)}
          className='rounded-full py-1 pl-4 pr-2 border-0 outline-0 lg:py-2 lg:pl-6 lg:border-0 lg:outline-0'
          placeholder='Nhập tên sản phẩm, thương hiệu cần tìm kiếm...'
          suffix={
            <div className='bg-primary opacity-75 hover:opacity-100 w-[32px] h-[32px] rounded-full flex items-center justify-center'>
              <SearchOutlined className='text-[18px] text-white cursor-pointer' />
            </div>
          }
        />
        <ul className='gap-3 px-5 text-sm !text-white hidden lg:flex'>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/sắt' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              sắt
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/perdays' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              perdays
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/axit folic' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              axit folic
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/mẹ bầu' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              mẹ bầu
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/vitamin tổng hợp' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              vitamin tổng hợp
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/lifespace' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              lifespace
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/cải thiện thị lực' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              cải thiện thị lực
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/men vi sinh' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              men vi sinh
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/mờ thâm' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              mờ thâm
            </a>
          </li>
          <li className='text-white text-md list-none'>
            <a href='/tim-kiem/tiêu hóa' className='text-white' onClick={() => dispatch(setRecentKeyword(keyword))}>
              tiêu hóa
            </a>
          </li>
          {/* {map(recentKeywords, (keyword) => (
            <li className='line-clamp-1 text-ellipsis'>
              <a href={`/tim-kiem/${keyword}`} className='text-white text-[10px]' onClick={() => dispatch(setRecentKeyword(keyword))}>
                {keyword}
              </a>
            </li>
          ))} */}
        </ul>
      </div>
    ),
    [dispatch, handleOnSearch, keyword]
  );

  const searchDropdown = useMemo(
    () => (
      <div className='bg-white w-[98%] left-[50%] translate-x-[-50%] lg:top-[-25px] !absolute rounded-xl shadow-2xl p-4 z-[9999] flex flex-col'>
        <div className='min-h-[150px]'>
          <Space className='flex items-center justify-between'>
            <Space direction='vertical' size={2}>
              <Typography.Title level={5} className='!mb-0 line-clamp-1 lg:line-clamp-2'>
                {find(banners, (item) => item?.name === "banner_search")?.title || "Healthymall"}
              </Typography.Title>
              <Typography className='my-0  line-clamp-1 lg:line-clamp-2'>
                {find(banners, (item) => item?.name === "banner_search")?.description || "Thực phẩm chức năng chính hãng từ Úc"}
              </Typography>
            </Space>
            <Image preview={false} src={find(banners, (item) => item?.name === "banner_search")?.url || "/perdays-logo-dark.svg"} width={120}></Image>
          </Space>
          <Divider className='my-4' />
          {isEmpty(recentKeywords) ? (
            <></>
          ) : (
            <>
              <div className='flex justify-between items-center'>
                <Typography.Title level={5}>Tìm kiếm gần đây</Typography.Title>
                <Typography className='text-primary cursor-pointer' onClick={() => dispatch(removeAllRecentKeyword([]))}>
                  Xóa lịch sử
                </Typography>
              </div>
              <Space direction='vertical' className='w-full'>
                {map(recentKeywords, (keyword) => (
                  <Link to={`/tim-kiem/${keyword}`} className='text-gray-600'>
                    <HistoryOutlined className='mr-2' />
                    {keyword}
                  </Link>
                ))}
              </Space>
              <Divider className='my-4' />
            </>
          )}
          <Typography.Title level={5}>Kết quả tìm kiếm</Typography.Title>
          <Space direction='vertical' split={<div className='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed'></div>}>
            {isEmpty(products) ? (
              <Typography className='text-gray-500'>Không tìm thấy sản phẩm</Typography>
            ) : (
              map(products, (item) => (
                <Link to={`/${item?.category}/${item?.url}`} className='flex items-center'>
                  <div className='mr-2'>
                    <Avatar src={item?.images?.[0]} shape='square' className='!w-[64px] !h-[64px] border' />
                  </div>
                  <div className='flex flex-col items-start justify-center'>
                    <div className='line-clamp-2'>{item?.name}</div>
                    <Space>
                      <Tag color='#50a199'>
                        <NumericFormat value={item?.discountPrice} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />
                      </Tag>
                    </Space>
                  </div>
                </Link>
              ))
            )}
          </Space>

          <Spin
            style={{ left: "50%", position: "absolute", transform: "translateX(-50%)" }}
            spinning={searching}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
        <div className='text-center mt-5'>
          <Link to={`/tim-kiem/${keyword}`} className='text-primary font-medium px-2 py-1 rounded-md' onClick={() => dispatch(setRecentKeyword(keyword))}>
            XEM THÊM SẢN PHẨM
          </Link>
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [banners, products, searching, keyword, recentKeywords]
  );

  const navbarElement = useMemo(
    () => (
      <ul className='w-full gap-5 text-sm ml-14 hidden lg:flex'>
        {map(categories ?? [], (category, index) => {
          if (index <= 7)
            return (
              <li key={index} className='list-none'>
                <a className='text-white hover:text-yellow-300 transition font-semibold' href={`/danh-muc/${category?.slug}`}>
                  {category?.name?.toUpperCase() || ""}
                </a>
              </li>
            );
        })}
        <li className='ml-auto list-none'>
          <a className='text-primary px-3 py-1 rounded-sm font-semibold bg-white text-center' href='/bai-viet'>
            <i className='bi bi-newspaper'></i> <span className='ml-1'>TIN TỨC</span>
          </a>
        </li>
      </ul>
    ),
    [categories]
  );

  const mobileHeaderElement = useMemo(
    () => (
      <div className='block lg:hidden'>
        <div className='flex items-center justify-between py-0 px-3 mb-1 text-white'>
          <div
            className='font-bold text-[26px] p-2 flex items-center justify-center cursor-pointer'
            onClick={toggleMobileMenu}
          >
            <i className={`bi ${isMobileMenuOpen ? 'bi-x-lg' : 'bi-list'}`} />
          </div>
          <Link to={"/"}>
            <img alt='logo' src='/logo.png' width={150} />
          </Link>
          <div className='flex items-center gap-4 pr-2'>
            <SearchOutlined
              className='text-[22px] cursor-pointer'
              onClick={() => setIsSearchModalOpen(true)}
            />
            <div className='relative'>
              <Dropdown arrow dropdownRender={() => <Cart />}>
                <Badge
                  count={reduce(
                    cart,
                    (acc, cur) => {
                      return (acc += cur?.quantity);
                    },
                    0
                  )}
                  offset={[-2, 2]}
                >
                  <ShoppingOutlined className='text-[22px] text-white cursor-pointer hover:opacity-80' />
                </Badge>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    ),
    [toggleMobileMenu, isMobileMenuOpen, cart]
  );

  const mobileMenuElement = useMemo(
    () => (
      <div className={`
        lg:hidden fixed top-[64px] left-0 w-full bg-white z-50 shadow-lg
        transition-transform duration-300 ease-in-out h-[calc(100vh-64px)] overflow-y-auto
        ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
      `}>
        <div className='h-full'>
          {/* User section - Điều chỉnh lại gradient với tone màu primary */}
          <div className='bg-gradient-to-br from-[#50a199] via-[#45918a] to-[#3b7e78] text-white py-4 px-5 relative overflow-hidden'>
            {currentUser ? (
              <div className='flex items-center gap-3 relative z-[1]'>
                <div className="relative">
                  <Avatar
                    src={currentUser?.avatar}
                    icon={<UserOutlined />}
                    size={48}
                    className="border-[3px] border-white/90 shadow-md"
                  >
                    {currentUser?.fullname?.[0] || currentUser?.username?.[0]}
                  </Avatar>
                  <div className="absolute -bottom-1 -right-1 w-4 h-4 bg-green-500 rounded-full border-2 border-white flex items-center justify-center">
                    <i className="bi bi-check text-[8px] text-white"></i>
                  </div>
                </div>
                <div className='flex-1 min-w-0'>
                  <Typography.Text className="text-base font-bold text-white mb-0.5 block">
                    {currentUser?.fullname || currentUser?.username}
                  </Typography.Text>
                  <div className="flex items-center gap-1.5 text-white/90 text-xs">
                    <i className="bi bi-envelope"></i>
                    <span className="truncate">{currentUser?.email}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className='flex items-center gap-4 cursor-pointer relative z-[1]'
                onClick={() => dispatch(setShowLoginModal(true))}
              >
                <Avatar
                  icon={<UserOutlined />}
                  size={56}
                  className="border-[3px] border-white/40 bg-white/20 shadow-md"
                />
                <div className='flex-1'>
                  <Typography.Text className="text-lg font-bold text-white mb-0.5 block">
                    Đăng nhập / Đăng ký
                  </Typography.Text>
                  <div className="flex items-center gap-2 text-white/90 text-sm">
                    <i className="bi bi-shield-check"></i>
                    <span>Quản lý tài khoản và đơn hàng</span>
                  </div>
                </div>
                <i className="bi bi-chevron-right text-white/90"></i>
              </div>
            )}
          </div>

          {/* Quick Actions Grid */}
          <div className="grid grid-cols-4 gap-1 px-2 py-1.5 border-b">
            {currentUser && (
              <>
                {/* Tài khoản - Luôn hiển thị đầu tiên */}
                <Link
                  to="/account"
                  className="flex flex-col items-center justify-center p-0.5"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center mb-0.5">
                    <i className="bi bi-person text-primary text-base"></i>
                  </div>
                  <span className="text-[10px] text-gray-600 text-center leading-tight min-h-[24px] flex items-center">
                    Tài khoản
                  </span>
                </Link>

                {/* Đơn hàng - Luôn hiển thị thứ hai */}
                <Link
                  to="/orders"
                  className="flex flex-col items-center justify-center p-0.5"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center mb-0.5">
                    <i className="bi bi-box-seam text-primary text-base"></i>
                  </div>
                  <span className="text-[10px] text-gray-600 text-center leading-tight min-h-[24px] flex items-center">
                    Đơn hàng
                  </span>
                </Link>

                {/* Quản trị hoặc Tin tức - Tùy theo role */}
                {(currentUser?.role === "admin" || currentUser?.role === "employee") ? (
                  <Link
                    to="/dashboard"
                    target="_blank"
                    className="flex flex-col items-center justify-center p-0.5"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center mb-0.5">
                      <i className="bi bi-speedometer2 text-primary text-base"></i>
                    </div>
                    <span className="text-[10px] text-gray-600 text-center leading-tight min-h-[24px] flex items-center">
                      Quản trị
                    </span>
                  </Link>
                ) : null}

                {/* Tin tức */}
                <Link
                  to="/bai-viet"
                  className="flex flex-col items-center justify-center p-0.5"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center mb-0.5">
                    <i className="bi bi-newspaper text-primary text-base"></i>
                  </div>
                  <span className="text-[10px] text-gray-600 text-center leading-tight min-h-[24px] flex items-center">
                    Tin tức
                  </span>
                </Link>

                {/* Đăng xuất - Chỉ hiển thị cho user thường */}
                {!(currentUser?.role === "admin" || currentUser?.role === "employee") && (
                  <div
                    className="flex flex-col items-center justify-center p-0.5 cursor-pointer"
                    onClick={() => {
                      handleLogout();
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center mb-0.5">
                      <i className="bi bi-box-arrow-right text-primary text-base"></i>
                    </div>
                    <span className="text-[10px] text-gray-600 text-center leading-tight min-h-[24px] flex items-center">
                      Đăng xuất
                    </span>
                  </div>
                )}
              </>
            )}
          </div>

          {/* Categories */}
          <div className='px-4 pt-4'>
            <Typography.Title level={5} className="!text-gray-600 !text-base !mb-3">
              <i className="bi bi-grid mr-2"></i>
              Danh mục sản phẩm
            </Typography.Title>
            <Space direction='vertical' className='w-full'>
              {map(categories, (category) => (
                <Link
                  key={category?.id}
                  to={`/danh-muc/${category?.slug}`}
                  className='flex items-center gap-3 py-2.5 text-gray-600 hover:text-primary transition-colors'
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <i className="bi bi-chevron-right text-sm"></i>
                  <span className='text-base leading-tight min-h-[24px] flex items-center'>{category?.name}</span>
                </Link>
              ))}
            </Space>
          </div>

           {currentUser && (
            <div className='mt-auto px-4 py-3 border-t'>
              <div
                onClick={() => {
                  handleLogout();
                  setIsMobileMenuOpen(false);
                }}
                className="flex items-center gap-3 py-2 text-gray-600 hover:text-primary transition-colors cursor-pointer"
              >
                <i className="bi bi-box-arrow-right text-lg"></i>
                <span className='text-base leading-tight min-h-[24px] flex items-center'>Đăng xuất</span>
              </div>
            </div>
          )}

        </div>
      </div>
    ),
    [isMobileMenuOpen, currentUser, categories, handleLogout, dispatch]
  );

  const desktopHeader = (
    <header className='hidden lg:flex gap-5 items-start w-full wide px-3 xl:px-0 mx-auto'>
      <div className='flex flex-1 gap-10 items-start justify-between'>
        <Logo src='/logo.png' width={200} />
        <Dropdown
          overlayStyle={{
            borderRadius: "15px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
          className='relative'
          trigger={"click"}
          dropdownRender={() => searchDropdown}
        >
          {searchElement}
        </Dropdown>
      </div>
      <div className='hidden md:flex lg:flex gap-5 h-12 items-center text-white'>
        <div className='flex gap-4 items-center'>
          <Dropdown arrow dropdownRender={() => <Hotline />}>
            <QuestionCircleOutlined className='text-[22px] text-white cursor-pointer hover:opacity-80' />
          </Dropdown>
          <Dropdown arrow dropdownRender={() => <Cart />}>
            {cartCounterElement}
          </Dropdown>
        </div>
        <span className='text-gray-300'>|</span>
        <Dropdown arrow={currentUser ? true : false} dropdownRender={() => (currentUser ? userElementDropdown : <></>)}>
          {userElement}
        </Dropdown>
      </div>
    </header>
  );

  const desktopNavbar = (
    <div className='hidden lg:flex wide items-center mx-auto w-full mt-4'>
      <div className='flex items-center'>
        <Dropdown
          arrow={true}
          overlayStyle={{
            borderRadius: "15px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
          dropdownRender={() => <MenuDropdown />}
        >
          {categoryElement}
        </Dropdown>
      </div>
      {navbarElement}
    </div>
  );

  return (
    <Fragment>
      <div className="hidden lg:block">
        <TopBanner
          src={find(banners, (item) => item?.name === "banner_top")?.url || "https://pic.pnnet.dev/1240x50"}
          color={find(banners, (item) => item?.name === "banner_top")?.color || "white"}
        />
      </div>
      <div className='bg-primary pt-2 mb-4 sticky top-0 z-[50] lg:py-2 lg:mb-4 lg:sticky lg:top-0 lg:z-[50]'>
        {mobileHeaderElement}
        {mobileMenuElement}
        {desktopHeader}
        {desktopNavbar}
      </div>
      <FloatButton.Group
        trigger='hover'
        style={{
          right: 24,
        }}
        icon={<AppstoreOutlined />}
        badge={{
          count: reduce(
            cart,
            (acc, cur) => {
              return (acc += cur?.quantity);
            },
            0
          ),
          color: "red",
        }}
      >
        <Space direction='vertical' size={12}>
          <Tooltip title='Giỏ hàng' placement='left'>
            <Link to={"/gio-hang"}>
              <FloatButton
                badge={{
                  count: reduce(
                    cart,
                    (acc, cur) => {
                      return (acc += cur?.quantity);
                    },
                    0
                  ),
                  color: "red",
                }}
                icon={<ShoppingCartOutlined />}
              />
            </Link>
          </Tooltip>
          <Tooltip title='Hỗ trợ' placement='left'>
            <a href='https://zalo.me/0967711633' target='_blank' rel='noreferrer'>
              <FloatButton icon={<CommentOutlined />} />
            </a>
          </Tooltip>
        </Space>
      </FloatButton.Group>

      <FloatButton.Group style={{ right: 80 }}>
        <Tooltip title='Lên đầu'>
          <FloatButton.BackTop duration={0} icon={<i className='bi bi-chevron-up'></i>} visibilityHeight={0} />
        </Tooltip>
      </FloatButton.Group>

      {isShowLoginModal && <Login />}
      {isShowRegisterModal && <Register />}

      {/* Search Modal */}
      <div className={`
        fixed inset-0 bg-black/60 z-[999] transition-all duration-300 backdrop-blur-sm
        ${isSearchModalOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}
      `}>
        <div className={`
          fixed top-0 left-0 right-0 bg-gradient-to-br from-[#50a199] via-[#45918a] to-[#3b7e78]
          transition-transform duration-300 shadow-lg
          ${isSearchModalOpen ? 'translate-y-0' : '-translate-y-full'}
        `}>
          {/* Header */}
          <div className='max-w-3xl mx-auto'>
            <div className='flex items-center justify-between p-4'>
              <Typography.Text className='text-white/90 text-base font-medium flex items-center'>
                <i className="bi bi-search mr-2 text-lg"></i>
                Tìm kiếm sản phẩm
              </Typography.Text>
              <div
                className='w-8 h-8 flex items-center justify-center rounded-full hover:bg-white/10 cursor-pointer transition-colors'
                onClick={() => setIsSearchModalOpen(false)}
              >
                <i className='bi bi-x-lg text-white/90 text-xl'></i>
              </div>
            </div>

            {/* Search Input */}
            <div className='px-4 pb-4'>
              <Input
                spellCheck={false}
                autoFocus
                onChange={(e) => handleOnSearch(e?.target?.value)}
                onBlur={(e) => setKeyword(e?.target?.value)}
                className='h-12 rounded-xl py-2.5 pl-5 pr-4 border-0 outline-0 shadow-lg text-base bg-white/95'
                placeholder='Nhập tên sản phẩm, thương hiệu...'
                prefix={<SearchOutlined className='text-gray-400 mr-2 text-lg' />}
              />
            </div>
          </div>

          {/* Search Results */}
          <div className='bg-white rounded-t-3xl shadow-xl'>
            <div className='max-w-3xl mx-auto p-6'>
              {/* Loading State */}
              {searching && (
                <div className='flex flex-col items-center justify-center py-12'>
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: '#50a199' }} spin />} />
                  <span className="text-gray-500 mt-3">Đang tìm kiếm...</span>
                </div>
              )}

              {/* Results */}
              {!searching && (
                <>
                  {/* Recent Searches - Fixed */}
                  {!isEmpty(recentKeywords) && (
                    <div className='mb-8'>
                      <div className='flex justify-between items-center mb-4'>
                        <Typography.Title level={5} className='!mb-0 !text-gray-700'>
                          <i className="bi bi-clock-history mr-2 text-primary/80"></i>
                          Tìm kiếm gần đây
                        </Typography.Title>
                        <Typography.Text
                          className='text-primary cursor-pointer hover:opacity-80 flex items-center'
                          onClick={() => dispatch(removeAllRecentKeyword([]))}
                        >
                          <i className="bi bi-trash3 mr-1"></i>
                          Xóa lịch sử
                        </Typography.Text>
                      </div>
                      <div className='flex flex-wrap gap-2'>
                        {map(recentKeywords, (keyword) => (
                          <Link
                            key={keyword}
                            to={`/tim-kiem/${keyword}`}
                            onClick={() => {
                              setIsSearchModalOpen(false);
                              dispatch(setRecentKeyword(keyword));
                            }}
                            className='px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-full text-sm text-gray-700 transition-colors flex items-center'
                          >
                            <i className="bi bi-clock text-gray-500 mr-1.5 text-xs"></i>
                            {keyword}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Search Results Title - Fixed */}
                  <Typography.Title level={5} className='!mb-4 !text-gray-700 flex items-center'>
                    <i className="bi bi-search mr-2 text-primary/80"></i>
                    Kết quả tìm kiếm
                    {!isEmpty(products) && (
                      <span className="ml-2 text-sm font-normal text-gray-500">
                        ({products.length} sản phẩm)
                      </span>
                    )}
                  </Typography.Title>

                  {/* Products List - Scrollable */}
                  <div className='max-h-[calc(100vh-400px)] overflow-y-auto'> {/* Chỉ scroll phần này */}
                    {isEmpty(products) ? (
                      <div className='text-center py-12 text-gray-500'>
                        <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center mx-auto mb-4">
                          <i className="bi bi-search text-2xl text-gray-400"></i>
                        </div>
                        <p className="text-gray-600 font-medium">Không tìm thấy sản phẩm</p>
                        <p className="text-sm text-gray-500 mt-1">Vui lòng thử lại với từ khóa khác</p>
                      </div>
                    ) : (
                      <div className='space-y-3'>
                        {map(products, (item) => (
                          <Link
                            key={item.id}
                            to={`/${item?.category}/${item?.url}`}
                            onClick={() => {
                              setIsSearchModalOpen(false);
                              dispatch(setRecentKeyword(keyword));
                            }}
                            className='flex items-center gap-4 p-3 hover:bg-gray-50 rounded-xl transition-colors border border-transparent hover:border-gray-200'
                          >
                            <Avatar
                              src={item?.images?.[0]}
                              shape='square'
                              className='!w-16 !h-16 !rounded-lg border border-gray-100'
                            />
                            <div className='flex-1 min-w-0'>
                              <div className='line-clamp-3 text-gray-800 text-sm font-medium mb-1'>
                                {item?.name}
                              </div>
                              {(item?.discountPrice || item?.price) && (
                                <Tag color='#50a199' className='mt-1 border-0'>
                                  <NumericFormat
                                    value={item?.discountPrice || item?.price}
                                    displayType='text'
                                    thousandSeparator='.'
                                    decimalSeparator=','
                                    suffix=' ₫'
                                  />
                                </Tag>
                              )}
                            </div>
                            <i className="bi bi-chevron-right text-gray-400"></i>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* View More Button - Fixed at bottom */}
                  {!isEmpty(products) && (
                    <Link
                      to={`/tim-kiem/${keyword}`}
                      onClick={() => {
                        setIsSearchModalOpen(false);
                        dispatch(setRecentKeyword(keyword));
                      }}
                      className='flex items-center justify-center gap-2 mt-6 text-primary font-medium hover:opacity-80 bg-primary/5 py-2.5 rounded-xl'
                    >
                      Xem tất cả kết quả
                      <i className="bi bi-arrow-right"></i>
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
