import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isNil, map, reduce } from "lodash";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { CheckCircleOutlined, DeleteOutlined, HomeOutlined, RightOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Divider, Drawer, Image, InputNumber, Modal, Radio, Space, Table, Tag, Tooltip, Typography, notification } from "antd";

import { updateProduct, removeProduct, deleteProduct } from "../redux/reducer/cartReducer";
import ProductSlider from "../components/ProductSlider";
import { generateDocumentNumber, paymentMethod } from "../utils";
import { createSaleOrder } from "../services/saleOrderService";
import TextArea from "antd/es/input/TextArea";
import { setShowLoginModal } from "../redux/reducer/modalReducer";

const CartPage = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state?.cartReducer);
  const { currentUser } = useSelector((state) => state?.authReducer?.login);
  const { recentProduct } = useSelector((state) => state?.recentProductReducer);
  const inputRef = useRef(null);
  const [value, setValue] = useState(reduce(cart, (acc, cur) => (acc += cur?.quantity * cur?.product?.discountPrice), 0));
  const [totalQuantity, setTotalQuantity] = useState(reduce(cart, (acc, cur) => (acc += cur?.quantity), 0));
  const [saleOrderDetail, setSaleOrderDetail] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Giỏ hàng";
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    setValue(reduce(cart, (acc, cur) => (acc += cur?.quantity * cur?.product?.discountPrice), 0));
    setTotalQuantity(reduce(cart, (acc, cur) => (acc += cur?.quantity), 0));
  }, [cart]);

  const confirm = (value) => {
    Modal.confirm({
      centered: true,
      title: "Thông báo",
      content: "Xác nhận đặt đơn hàng?",
      cancelText: "Hủy bỏ",
      okText: "Đồng ý",
      onOk: async () => {
        const details = !isNil(saleOrderDetail) ? saleOrderDetail : cart;
        const documentNumber = generateDocumentNumber();
        if (details?.length > 0) {
          await createSaleOrder({ details, documentNumber, userId: currentUser?._id, description: inputRef.current.resizableTextArea.textArea.value })
            .then((response) => {
              navigate("/checkout", { state: response });
              dispatch(deleteProduct());
              notification.success({ description: "Đặt hàng thành công!", placement: "top" });
            })
            .catch((err) => notification.error({ description: err?.message || "Có lỗi xảy ra!", placement: "top" }));
        } else {
          notification.error({ description: "Chưa có sản phẩm trong giỏ hàng!", placement: "top" });
        }
      },
      okType: "primary",
      okButtonProps: {
        style: {
          backgroundColor: "#50a199",
        },
      },
      icon: <CheckCircleOutlined className='!text-primary' />,
    });
  };

  const columns = [
    {
      title: "Hình ảnh",
      dataIndex: "image",
      align: "center",
      render: (src, record) => <img src={record?.images?.[0]} alt='' width={60} height={60} className='rounded-md' />,
    },
    {
      title: "Sản phẩm",
      align: "center",
      dataIndex: "productName",
      render: (text, record) => (
        <Link to={`/${record?.category}/${record?.url}`} className='line-clamp-2 flex text-left' href='#'>
          <span className='line-clamp-2'>{text}</span>
        </Link>
      ),
      width: 300,
    },
    {
      title: "Đơn giá",
      dataIndex: "discountPrice",
      render: (value) => <NumericFormat className='font-medium' value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      render: (value, record) => (
        <div className='flex items-center mt-auto ml-auto text-left'>
          <InputNumber
            defaultValue={value}
            onChange={(val) => dispatch(updateProduct({ product: record?.product, quantity: val }))}
            className='w-14 border mx-1'
            // controls={false}
            min={0}
            max={record?.product?.stock}
          />
        </div>
      ),
    },
    {
      title: "Thành tiền",
      dataIndex: "amount",
      render: (value) => <NumericFormat className='font-medium' value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
    },
    {
      title: "Tùy chọn",
      render: (value, record) => (
        <Tooltip title='Xóa'>
          <div className='text-center cursor-pointer' onClick={() => dispatch(removeProduct(record?._id))}>
            <DeleteOutlined />
          </div>
        </Tooltip>
      ),
    },
  ];

  const dataSource = useMemo(() => {
    return map(cart, (item, index) => {
      return {
        ...item,
        ...item?.product,
        key: index,
        image: item?.product?.image,
        discountPrice: item?.product?.discountPrice,
        productName: item?.product?.name,
        quantity: item?.quantity,
        amount: item?.product?.discountPrice * item?.quantity,
      };
    });
  }, [cart]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const totalSelectedAmount = reduce(selectedRows, (acc, cur) => (acc += cur?.amount), 0);
      const totalSelectedQuantity = reduce(selectedRows, (acc, cur) => (acc += cur?.quantity), 0);
      setValue(totalSelectedAmount);
      setTotalQuantity(totalSelectedQuantity);
      setSaleOrderDetail(selectedRows);
    },
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Breadcrumb
        className='wide mx-auto mb-4 px-4 md:px-6 lg:px-0'
        items={[
          {
            href: "/",
            title: (
              <>
                <HomeOutlined />
                <span>Trang chủ</span>
              </>
            ),
          },
          {
            title: <span>Giỏ hàng</span>,
          },
        ]}
      />
      <div className='wide mx-auto flex flex-col lg:flex-row gap-4 px-4 md:px-6 lg:px-0'>
        <div className='w-full lg:w-[75%] float-none lg:float-left'>
          <Table
            title={() => <Typography className='font-medium'>{`Giỏ hàng (${totalQuantity})`}</Typography>}
            className='rounded-md mb-4'
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
              defaultSelectedRowKeys: map(dataSource, (item) => item?.key),
            }}
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 'max-content' }}
          />

          <Card title='Nhập ghi chú' headStyle={{ fontWeight: 500, fontSize: 14 }} bordered={false}>
            <TextArea ref={inputRef} />
          </Card>

          <Card title='Chọn phương thức thanh toán' headStyle={{ fontWeight: 500, fontSize: 14 }} bordered={false}>
            <Space direction='vertical' size={18} className='w-full'>
              {map(paymentMethod, (p) => (
                <Tooltip placement='bottom' title={p?.value === "cash" ? "Thanh toán khi nhận hàng" : "Chưa hỗ trợ"}>
                  <Radio value={p?.value} disabled={p?.value !== "cash"} checked={p?.value === "cash" ? true : false}>
                    <div className='flex !items-center justify-between gap-3'>
                      <Image src={p?.image} width={32} preview={false} className='inline-block' />
                      <Typography>{p?.label}</Typography>
                    </div>
                  </Radio>
                </Tooltip>
              ))}
            </Space>
          </Card>
          <ProductSlider title='Sản phẩm đã xem' products={recentProduct} sliderPerView={4} buttonElm='recent' />
        </div>
        <div className='w-full lg:w-[25%] bg-white flex flex-col p-3 rounded-md gap-2 lg:sticky lg:top-44 order-first lg:order-last mb-4 lg:mb-0 lg:h-[350px]'>
          <Tag color='green' className='flex justify-between mb-3 rounded-md py-3 w-full mr-0 border-none'>
            <Typography className='pl-2'>Mã giảm giá</Typography>
            <Typography className='pr-2 self-center !text-primary text-sm cursor-pointer' onClick={showDrawer}>
              Chọn mã <RightOutlined />
            </Typography>
          </Tag>
          <Space className='flex justify-between'>
            <Typography>Tạm tính</Typography>
            <NumericFormat className='text-base font-medium' value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />
          </Space>
          <Space className='flex justify-between'>
            <Typography>Chiết khấu</Typography>
            <NumericFormat className='text-base font-medium' value={0} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />
          </Space>
          <Space className='flex justify-between'>
            <Typography>Mã giảm giá</Typography>
            <NumericFormat className='text-base font-medium' value={0} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />
          </Space>
          <Divider className='my-1'></Divider>
          <Space className='flex justify-between'>
            <Typography>Phí vận chuyển</Typography>
            <NumericFormat className='text-base font-medium' value={0} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />
          </Space>
          <Divider className='my-1'></Divider>
          <Space className='flex justify-between'>
            <Typography>Tổng tiền</Typography>
            <NumericFormat
              className='text-orange-500 font-bold text-[24px]'
              value={value}
              displayType='text'
              thousandSeparator='.'
              decimalSeparator=','
              suffix=' ₫'
            />
          </Space>
          <Button
            type='primary'
            size='large'
            className='mt-3 !h-12 md:!h-16 !font-semibold !text-base md:!text-lg'
            onClick={() => {
              if (currentUser?._id) {
                confirm();
              } else {
                dispatch(setShowLoginModal(true));
              }
            }}
          >
            Đặt hàng<span className='ml-1'>({totalQuantity})</span>
          </Button>
        </div>
      </div>
      <Drawer title='Chọn mã khuyến mãi' onClose={onClose} open={open}>
        <div className='flex flex-col h-full w-full'>
          <Typography>Hiện tại chưa có mã giảm giá</Typography>
          <Button className='mt-auto !h-12 !font-semibold !text-lg' size='large' type='primary'>
            Áp dụng
          </Button>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default CartPage;
