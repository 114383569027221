import React, { useEffect, useMemo } from "react";
import Product from "../Product";
import { useQuery } from "react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { getAllProduct } from "../../services/productServices";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import { Button, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { isArray, isEmpty, map } from "lodash";

const ProductSlider = (props) => {
  const {
    data: productList,
    isFetching,
    refetch,
  } = useQuery("getAllProductSlider", {
    queryFn: async () => {
      if (props?.params) {
        const params = props.params;
        const response = await getAllProduct({ params });
        return response?.data;
      }
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const products = useMemo(() => {
    if (productList && productList.length >= 0) return productList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, productList]);

  return (
    <div className={`${props?.className} wide my-8 relative group mx-auto rounded-lg`}>
      <Typography className='font-semibold text-[20px] mb-4'>{props?.title}</Typography>
      <Swiper
        slidesPerView={props?.sliderPerView ? props.sliderPerView : 4}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 8
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: props?.sliderPerView ? props.sliderPerView : 4,
            spaceBetween: 12
          }
        }}
        autoplay={{ pauseOnMouseEnter: true, delay: 5000 }}
        navigation={{
          nextEl: `.button-next-${props?.buttonElm}`,
          prevEl: `.button-prev-${props?.buttonElm}`,
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[Navigation, Autoplay]}
        className='mySwiper'
      >
        {map(isArray(props?.products) && !isEmpty(props?.products) ? props.products : products, (elm) => (
          <SwiperSlide className='rounded-lg'>
            <Product product={elm} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Button
        className={`absolute right-2 md:right-4 top-[50%] translate-y-[-50%] button-next-${props?.buttonElm} z-10 group-hover:scale-125 group-hover:opacity-100 opacity-0 flex justify-center items-center duration-300 rounded-full w-7 h-7 md:w-9 md:h-9 bg-white`}
      >
        <RightOutlined className='text-primary' />
      </Button>
      <Button
        className={`absolute left-2 md:left-4 top-[50%] translate-y-[-50%] button-prev-${props?.buttonElm} z-10 group-hover:scale-125 group-hover:opacity-100 opacity-0 flex justify-center items-center duration-300 rounded-full w-7 h-7 md:w-9 md:h-9 bg-white`}
      >
        <LeftOutlined className='text-primary' />
      </Button>
    </div>
  );
};

export default ProductSlider;
