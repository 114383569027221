import { DeleteOutlined } from "@ant-design/icons";
import { Button, Divider, Typography } from "antd";
import LinkAntd from "antd/es/typography/Link";
import { map, reduce } from "lodash";
import React from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeProduct } from "../../../redux/reducer/cartReducer";

const Cart = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cartReducer);

  return (
    <div className='bg-white lg:w-[350px] w-[280px] shadow-2xl rounded-lg border'>
      {/* Header */}
      <div className="p-3 border-b flex items-center justify-between bg-gray-50 rounded-t-lg">
        <Typography.Title level={5} className="!mb-0 !text-base">
          Giỏ hàng ({cart.length})
        </Typography.Title>
      </div>

      {/* Cart Items */}
      <div className="p-3 max-h-[400px] overflow-y-auto">
        {map(cart, (element, index) => (
          <div key={index} className='mb-3'>
            <div className='flex items-start gap-2'>
              <LinkAntd
                onClick={() => (window.location.pathname = `/${element?.product?.category}/${element?.product?.url}`)}
                className='flex flex-1 min-w-0'
              >
                <img
                  className='border p-1 rounded-lg mr-2 w-[50px] h-[50px] object-cover flex-shrink-0'
                  src={element?.product?.images?.[0]}
                  alt=''
                />
                <div className='flex-1 min-w-0'>
                  <p className='font-medium line-clamp-2 !mb-1 text-gray-700 text-sm'>
                    {element?.product?.name}
                  </p>
                  <div className='flex items-center justify-between'>
                    <NumericFormat
                      className='text-primary font-medium text-sm'
                      value={element?.product?.discountPrice}
                      displayType='text'
                      thousandSeparator='.'
                      decimalSeparator=','
                      suffix=' ₫'
                    />
                    <span className='text-gray-500 font-light text-sm ml-1'>x{element?.quantity}</span>
                  </div>
                </div>
              </LinkAntd>
              <div
                className='flex-shrink-0 w-7 h-7 flex items-center justify-center rounded-lg hover:bg-gray-100 cursor-pointer text-gray-500 transition-colors'
                onClick={() => dispatch(removeProduct(element?.product?.url))}
              >
                <DeleteOutlined className="text-sm" />
              </div>
            </div>
            <Divider className='my-2' />
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className='p-3 border-t bg-gray-50 rounded-b-lg'>
        <div className='flex items-center justify-between mb-2'>
          <span className="text-gray-500 text-sm">Tổng tiền:</span>
          <NumericFormat
            className='text-base font-medium text-primary'
            value={reduce(
              cart,
              (acc, cur) => acc + cur?.product?.discountPrice * cur?.quantity,
              0
            )}
            displayType='text'
            thousandSeparator='.'
            decimalSeparator=','
            suffix=' ₫'
          />
        </div>
        <Button type='primary' block className="rounded-lg">
          <Link to="/gio-hang" className="text-white text-sm">
            Xem giỏ hàng
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default Cart;
