import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { find, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { setBanners, setSliders } from "../../redux/reducer/imageReducer";
import { getBanner, getSlider } from "../../services/flieServices";

const Banner = () => {
  const { banners, sliders } = useSelector((state) => state?.imageReducer);

  const dispatch = useDispatch();

  const { data: bannerList, isSuccess } = useQuery({ queryKey: "getBanners", queryFn: getBanner, refetchOnWindowFocus: false });

  const { data: sliderList, isSuccess: getSliderSuccess } = useQuery({ queryKey: "getSliders", queryFn: getSlider, refetchOnWindowFocus: false });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setBanners(bannerList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (getSliderSuccess) {
      dispatch(setSliders(sliderList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSliderSuccess]);

  return (
    <div className="w-full bg-[#f5f5f5]">
      <div className='
        container
        max-w-[1200px]
        relative
        w-full
        wide
        px-3 sm:px-4 lg:px-6 xl:px-0
        flex flex-col lg:flex-row
        gap-3 lg:gap-[15px]
        lg:h-[300px]
        lg:overflow-hidden
        mx-auto
        group'
      >
        {/* Main Slider */}
        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          navigation={{
            nextEl: ".button-next-elm",
            prevEl: ".button-prev-elm",
          }}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            pauseOnMouseEnter: true,
            delay: 3000
          }}
          keyboard={{
            enabled: true,
          }}
          className='mySwiper w-full lg:flex-1 rounded-xl'
        >
          {map(sliders, (item, index) => (
            <SwiperSlide key={index}>
              <Image
                src={item?.url || "https://pic.pnnet.dev/1590x560"}
                alt=''
                className='w-full h-full object-cover'
              />
            </SwiperSlide>
          ))}

          {/* Navigation Buttons */}
          <Button className='
            absolute right-2 sm:right-5
            top-1/2 -translate-y-1/2
            button-next-elm z-10
            group-hover:opacity-100
            scale-90 sm:scale-125
            opacity-0
            flex justify-center items-center
            duration-300 rounded-full
            w-8 h-8 sm:w-9 sm:h-9
            bg-white/80 hover:bg-white
          '>
            <RightOutlined className='text-primary' />
          </Button>
          <Button className='
            absolute left-2 sm:left-5
            top-1/2 -translate-y-1/2
            button-prev-elm z-10
            group-hover:opacity-100
            scale-90 sm:scale-125
            opacity-0
            flex justify-center items-center
            duration-300 rounded-full
            w-8 h-8 sm:w-9 sm:h-9
            bg-white/80 hover:bg-white
          '>
            <LeftOutlined className='text-primary' />
          </Button>
        </Swiper>

        {/* Side Banners */}
        <div className='
          grid grid-cols-2 lg:grid-cols-1
          gap-3 lg:gap-[15px]
          w-full lg:w-[372px]
          sm:h-auto
        '>
          {/* Banner 1 */}
          <div className='
            col-span-1
            aspect-[16/6] sm:aspect-[16/7] lg:aspect-[16/6]
            rounded-xl overflow-hidden
            transition-transform duration-300
          '>
            <Image
              src={find(banners, (item) => item?.name === "banner1")?.url || "https://pic.pnnet.dev/1490x570"}
              alt='Banner 1'
              className='w-full h-full object-cover rounded-xl'
              loading='lazy'
            />
          </div>

          {/* Banner 2 */}
          <div className='
            col-span-1
            aspect-[16/6] sm:aspect-[16/7] lg:aspect-[16/6]
            rounded-xl overflow-hidden
            transition-transform duration-300
          '>
            <Image
              src={find(banners, (item) => item?.name === "banner2")?.url || "https://pic.pnnet.dev/1490x570"}
              alt='Banner 2'
              className='w-full h-full object-cover rounded-xl'
              loading='lazy'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
