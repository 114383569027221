import { Card, Col, Collapse, Pagination, Row, Spin, Tag, Typography, notification } from "antd";
import { find, map } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getAllNews } from "../services/newsServices";
import { getAllSubject } from "../services/subjectServices";

const SubjectPage = () => {
  const { subject: subjectSlug } = useParams();

  const [pagination, setPagination] = useState({ page: 1, size: 6 });

  const {
    data: news,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: "getAllNews",
    queryFn: async () => {
      const data = await getAllNews({ params: { subject: subjectSlug, ...pagination } })
        .then((res) => res)
        .catch((err) =>
          notification.error({
            message: err?.message || err?.data?.message || "Có lỗi xảy ra!",
          })
        );
      return data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: subjects } = useQuery({ queryKey: "getAllSubject", queryFn: getAllSubject, enabled: true, refetchOnWindowFocus: false });

  const posts = useMemo(() => {
    if (news?.data && news?.data?.length >= 0) {
      const formattedNews = map(news?.data ?? [], (item) => ({
        ...item,
        subject: find(subjects, (elm) => elm?.slug === item?.subject)?.name,
      }));
      return formattedNews;
    }
  }, [news, subjects]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <>
      <div className='wide mt-4 md:mt-6 px-4 md:px-6 lg:px-0'>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={18} className='flex flex-col items-center'>
            <div className='flex flex-col gap-3 md:gap-4 w-full'>
              {map(posts, (item, index) => (
                <Card
                  bordered={false}
                  bodyStyle={{
                    padding: "16px",
                  }}
                  className='shadow-sm rounded-md w-full'
                >
                  <div className='flex flex-col md:flex-row gap-4'>
                    <div
                      className='w-full md:w-[280px] lg:w-[200px] h-[200px] md:h-[180px] rounded-lg'
                      style={{
                        backgroundImage: `url(${item?.images?.[0]})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <div className='flex-1'>
                      <Link to={`/bai-viet/${item?.url}`}>
                        <Tag color='#edf0f3' className='font-medium px-2 mb-2 rounded-sm !text-[#727886] text-xs md:text-sm'>
                          {item?.subject}
                        </Tag>
                        <Typography.Title level={5} className='!mb-2 line-clamp-2 text-base md:text-lg'>
                          {item?.title}
                        </Typography.Title>
                        <Typography className='m-0 !line-clamp-2 md:!line-clamp-3 text-sm md:text-base font-normal'>
                          {item?.description}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
            <Pagination
              className='mt-6 md:mt-8'
              total={news?.total ?? 0}
              onChange={(page, size) => setPagination({ page, size })}
              showTotal={(total) => `Tổng ${total} bài viết`}
              showTitle
              pageSize={pagination?.size}
              responsive={true}
              size={window.innerWidth < 768 ? 'small' : 'default'}
            />
          </Col>
          <Col xs={24} className='block lg:hidden mb-4'>
            <Collapse
              items={[{
                key: '1',
                label: 'Chuyên mục nổi bật',
                children: (
                  <div className='flex flex-col gap-3'>
                    {map(subjects, (subject) => (
                      <Link to={`/chuyen-muc/${subject?.slug}`} key={subject?.slug}>
                        <div className='flex items-center gap-3 p-2 rounded-lg hover:bg-gray-50'>
                          <div
                            className='!w-[48px] !h-[48px] md:!w-[56px] md:!h-[56px] rounded-lg'
                            style={{
                              backgroundImage: `url(${subject?.image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                            }}
                          />
                          <div className='flex-1'>
                            <Typography className='line-clamp-1 font-semibold text-sm md:text-base'>
                              {subject?.name}
                            </Typography>
                            <Typography className='text-xs md:text-sm font-light line-clamp-2'>
                              {subject?.description}
                            </Typography>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ),
              }]}
            />
          </Col>
          <Col lg={6} className='hidden lg:block'>
            <div className='bg-white shadow-sm flex flex-col gap-3 p-3'>
              <Typography.Title level={4} className='m-3'>
                Chuyên mục nổi bật
              </Typography.Title>
              {map(subjects, (subject) => (
                <Link to={`/chuyen-muc/${subject?.slug}`}>
                  <div className='flex items-center gap-3 p-2 rounded-lg hover:bg-gray-50'>
                    <div
                      className='!w-[64px] !h-[64px]'
                      style={{
                        backgroundImage: `url(${subject?.image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    />
                    <div className='flex-1'>
                      <Typography className='line-clamp-1 font-semibold'>
                        {subject?.name}
                      </Typography>
                      <Typography className='text-sm font-light line-clamp-1'>
                        {subject?.description}
                      </Typography>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <Spin fullscreen spinning={isFetching} tip='Đang tải dữ liệu...' />
    </>
  );
};

export default SubjectPage;
