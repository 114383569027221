import React, { Fragment, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAllNews } from "../services/newsServices";
import { Breadcrumb, Divider, Space, Spin, Tag, Tooltip, Typography, notification } from "antd";
import { getAllSubject } from "../services/subjectServices";
import { find } from "lodash";
import moment from "moment";
import { HomeOutlined } from "@ant-design/icons";

const PostPage = () => {
  const { url } = useParams();
  const { data: post, isFetching } = useQuery({
    queryKey: `getPost-${url}`,
    queryFn: async () => {
      const data = await getAllNews({ params: { url } })
        .then((res) => res?.[0])
        .catch((err) =>
          notification.error({
            message: err?.message || err?.data?.message || "Có lỗi xảy ra!",
          })
        );
      return data;
    },
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const { data: subjects, isFetching: isFetchingSubjects } = useQuery({
    queryKey: `getAllSubject`,
    queryFn: getAllSubject,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const formattedPost = useMemo(() => {
    if (post) {
      return {
        ...post,
        content: post?.content?.replace(/<li/g, "<li style='margin-left: 24px;'"),
        subject: find(subjects, (item) => item?.slug === post?.subject)?.name,
      };
    }
  }, [post, subjects]);

  useEffect(() => {
    document.title = formattedPost?.title;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [formattedPost]);

  return (
    <Fragment>
      <Breadcrumb
        className='wide mx-auto mb-2 md:mb-4 px-4 md:px-6 lg:px-0'
        items={[
          {
            href: "/",
            title: (
              <>
                <HomeOutlined />
                <span>Trang chủ</span>
              </>
            ),
          },
          {
            href: "/bai-viet",
            title: "Bài viết",
          },
          {
            title: (
              <Tooltip className='max-w-[200px] md:max-w-[300px] lg:max-w-96 line-clamp-1' title={formattedPost?.title}>
                {formattedPost?.title || "Chi tiết bài viết"}
              </Tooltip>
            ),
          },
        ]}
      />
      <div className='wide mx-auto'>
        <div className='w-full md:w-full lg:w-[800px] mx-auto px-4 md:px-6 lg:px-0'>
          <Typography.Title
            level={4}
            className='line-clamp-3 text-lg md:text-xl lg:text-2xl'
          >
            {formattedPost?.title}
          </Typography.Title>

          <Space className='mb-4 md:mb-6 lg:mb-8 flex-wrap'>
            <Tag color='#525252' className='font-medium px-2 rounded-sm !text-white text-xs md:text-sm'>
              {formattedPost?.subject || ""}
            </Tag>
            <Divider className='mx-1' type='vertical' />
            <Typography className='text-xs md:text-sm'>
              {moment(formattedPost?.updatedAt).format("HH:MM DD/MM/YYYY")}
            </Typography>
          </Space>

          <div
            dangerouslySetInnerHTML={{ __html: formattedPost?.content }}
            className='post-content text-sm md:text-base'
          />
        </div>
      </div>
      <Spin spinning={isFetching && isFetchingSubjects} fullscreen tip='Đang tải dữ liệu...' />
    </Fragment>
  );
};

export default PostPage;
