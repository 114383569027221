import {
  CheckOutlined,
  CloudUploadOutlined,
  CodeSandboxOutlined,
  EditOutlined,
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  PercentageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Breadcrumb, Button, Card, Col, Form, Image, Input, Menu, Row, Select, Space, Tag, Tooltip, Typography, notification } from "antd";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { filter, isString, map, toUpper } from "lodash";
import Meta from "antd/es/card/Meta";
import Dragger from "antd/es/upload/Dragger";
import { useDispatch, useSelector } from "react-redux";
import { uploadMedia } from "../services/uploadServices";
import { getRole, roles } from "../utils";
import { getDistrict, getProvince, getWard } from "../services/provinceServices";
import { getUser, updateUser, logoutUser } from "../services/userServices";
import { updateCurrentUser } from "../redux/reducer/authReducer";

const ProfilePage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state?.authReducer?.login);

  const [user, setUser] = useState(currentUser);

  const { data: province } = useQuery({ queryKey: "getProvince", queryFn: getProvince, refetchOnWindowFocus: false });

  const { data: district } = useQuery({ queryKey: "getDistrict", queryFn: getDistrict, refetchOnWindowFocus: false });

  const { data: ward, isSuccess } = useQuery({ queryKey: "getWard", queryFn: getWard, refetchOnWindowFocus: false });

  const { refetch } = useQuery({
    queryKey: "getUser",
    queryFn: async () => {
      const data = await getUser({ _id: currentUser?._id });
      if (data) {
        dispatch(updateCurrentUser(data));
        setUser(data);
      }
    },
    refetchOnWindowFocus: false,
  });

  const [address, setAddress] = useState({ province: [], district: [], ward: [] });

  const [updating, setUpdating] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const getItem = (label, key, icon, children, disabled) => ({ key, icon, children, label, disabled });

  const handleLogout = async () => {
    await logoutUser(null, dispatch)
      .then((res) => notification.success({ description: res?.response?.data?.message || "Đăng xuất thành công!", placement: "top" }))
      .finally(() => navigate("/"));
    // .finally(() => navigate("/"));
  };

  const handleOnSubmit = async (values) => {
    setUpdating(true);
    if (!isString(values?.avatar)) {
      const formData = new FormData();
      formData.append("file", values?.avatar?.file);
      await uploadMedia(formData)
        .then((res) => (values.avatar = res?.url))
        .catch((err) => notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } }));
    }
    await updateUser(values)
      .then(() => {
        notification.success({ description: "Cập nhật thành công !", placement: "top", style: { width: "300px" } });
      })
      .catch((err) => {
        notification.error({ description: err?.response?.data?.message, placement: "top", style: { width: "340px" } });
      })
      .finally(() => {
        setUpdating(false);
        refetch();
      });
  };

  useEffect(() => {
    setAddress({ province, district, ward });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    document.title = "Thông tin cá nhân";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const draggerProps = {
    accept: ["image/jpg", "image/jpeg", "image/png"],
    multiple: false,
    showUploadList: true,
    beforeUpload: () => false,
  };

  const items = [
    getItem(<Link to={"/account"}>Thông tin cá nhân</Link>, "1", <UserOutlined />, null, false),
    getItem(<Link to={"/orders"}>Đơn hàng</Link>, "2", <CodeSandboxOutlined />, null, false),
    getItem(<Tooltip title='Chức năng đang phát triển'>Quản lý địa chỉ</Tooltip>, "3", <i className='bi bi-geo-alt'></i>, null, true),
    getItem(<Tooltip title='Chức năng đang phát triển'>Mã giảm giá</Tooltip>, "4", <PercentageOutlined />, null, true),
    getItem(<Typography onClick={handleLogout}>Đăng xuất</Typography>, "5", <i className='bi bi-box-arrow-right'></i>, null, false),
  ];

  return (
    <Fragment>
      <Breadcrumb
        className='wide mx-auto mb-4 px-4 md:px-6 lg:px-0'
        items={[
          {
            href: "/",
            title: (
              <>
                <HomeOutlined />
                <span>Trang chủ</span>
              </>
            ),
          },
          {
            title: <span>Thông tin cá nhân</span>,
          },
        ]}
      />
      <div className='wide mx-auto rounded-lg bg-white py-6 px-4 flex flex-col md:flex-row gap-3'>
        <div className='border rounded-lg w-full md:w-[256px]'>
          <Card
            style={{
              width: '100%'
            }}
            bodyStyle={{ padding: "24px 6px" }}
            className='rounded-none border-none [&_.ant-card-actions_li]:list-none'
            actions={[
              <Tooltip title='Chỉnh sửa'>
                <EditOutlined onClick={() => setDisabled(false)} />
              </Tooltip>,
              <Tooltip title='Xong'>
                <CheckOutlined onClick={() => setDisabled(true)} />
              </Tooltip>,
            ]}
          >
            <Meta
              className='flex items-center'
              avatar={<Avatar shape='square' src={user?.avatar} icon={<UserOutlined />} className='w-[90px] h-[90px] items-center flex justify-center' />}
              title={user?.fullname || user?.username}
              description={
                <Tag bordered={false} color={user?.role === "user" ? "green-inverse" : user?.role === "employee" ? "orange-inverse" : "red-inverse"}>
                  {getRole(user?.role)?.label}
                </Tag>
              }
            />
          </Card>
          <Menu
            style={{
              width: '100%'
            }}
            className='ant-menu-customize rounded-none border-none py-3'
            defaultSelectedKeys={["1"]}
            mode='inline'
            items={items}
          />
        </div>

        <div className='flex-1'>
          <Card>
            <Form
              disabled={disabled}
              id='editUserForm'
              initialValues={user}
              onFinish={handleOnSubmit}
              spellCheck={false}
              labelCol={{ span: 24 }}
              wrapperCol={24}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={16}>
                  <Row justify={"center"} gutter={[12, 12]}>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        name='username'
                        label='Tài khoản'
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập tên đăng nhập!",
                          },
                        ]}
                      >
                        <Input placeholder='Tên đăng nhập' allowClear={true} prefix={<UserOutlined className='mr-2 text-gray-500' />} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label='Phân quyền' name='role'>
                        <Select placeholder='Phân quyền' disabled options={roles} prefix={<LockOutlined className='mr-2 text-gray-500' />} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        name='email'
                        label='Email'
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập email!",
                          },
                        ]}
                      >
                        <Input type='email' placeholder='Email' allowClear={true} prefix={<MailOutlined className='mr-2 text-gray-500' />} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        name='phone'
                        label='Số điện thoại'
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập số điện thoại!",
                          },
                        ]}
                      >
                        <Input type='number' placeholder='Số điện thoại' allowClear={true} prefix={<MobileOutlined className='mr-2 text-gray-500' />} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label='Họ và tên' name='fullname'>
                        <Input placeholder='Họ và tên' allowClear={true} prefix={<EditOutlined className='mr-2 text-gray-500' />} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item label='Địa chỉ' name='road'>
                        <Input placeholder='Nhập số nhà tên đường' allowClear={true} prefix={<HomeOutlined className='mr-2 text-gray-500' />} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Form.Item label='Tỉnh/Thành phố' name='province'>
                        <Select
                          placeholder='Chọn tỉnh thành phố'
                          allowClear={true}
                          options={map(address.province, (item) => ({ label: item?.name, value: item?.idProvince }))}
                          showSearch={true}
                          optionFilterProp='label'
                          virtual={false}
                          onSelect={(val) => {
                            const filteredDistrict = filter(address.district, (item) => item?.idProvince === val);
                            setAddress((prev) => ({ ...prev, district: filteredDistrict }));
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Form.Item label='Quận/Huyện' name='district'>
                        <Select
                          placeholder='Chọn quận huyện'
                          allowClear={true}
                          options={map(address?.district, (item) => ({ label: item?.name, value: item?.idDistrict }))}
                          showSearch={true}
                          optionFilterProp='label'
                          virtual={true}
                          onSelect={(val) => {
                            const filteredWard = filter(address.ward, (item) => item?.idDistrict === val);
                            setAddress((prev) => ({ ...prev, ward: filteredWard }));
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Form.Item name='ward' label='Phường/Xã'>
                        <Select
                          placeholder='Chọn phường xã'
                          allowClear={true}
                          options={map(address?.ward, (item) => ({ label: item?.name, value: item?.idWard }))}
                          showSearch={true}
                          optionFilterProp='label'
                          virtual={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} lg={8}>
                  <Row>
                    <Col align='center' span={24}>
                      <Space direction='vertical' className='mb-5'>
                        <Image
                          src={user?.avatar}
                          width={150}
                          height={150}
                          className='rounded-full border'
                        />
                        <Button type='primary' className='font-Kanit font-normal cursor-pointer text-white'>
                          {toUpper(user?.fullname || user?.username)}
                        </Button>
                      </Space>
                    </Col>
                    <Col align='center' span={24}>
                      <Form.Item name='avatar' className='w-full max-w-[300px] mx-auto'>
                        <Dragger {...draggerProps} name='avatar' listType='text'>
                          <p className='ant-upload-drag-icon'>
                            <CloudUploadOutlined />
                          </p>
                          <p className='ant-upload-text'>Kéo thả hình ảnh vào đây</p>
                          <p className='ant-upload-hint'>Để thay đổi ảnh đại diện</p>
                        </Dragger>
                      </Form.Item>
                    </Col>
                    <Col align='center' span={24}>
                      <Space className='flex items-center justify-center'>
                        <Button onClick={() => setDisabled(true)}>Hủy bỏ</Button>
                        <Button type='primary' htmlType='submit' form='editUserForm' loading={updating}>
                          Cập nhật
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfilePage;
