import { Col, Row } from "antd";
import React from "react";
import Post from "../Post";
import { map } from "lodash";

const TopPost = ({ primaryPost, sidePosts }) => {
  return (
    <div className='mb-4 md:mb-6'>
      <Row gutter={[16, 16]} className='md:gutter-[30]'>
        <Col xs={24} md={14} lg={15}>
          <Post width={"100%"} position='top' post={primaryPost} />
        </Col>
        <Col xs={24} md={10} lg={9} className="max-h-[480px] overflow-y-auto posts-sidebar">
          <div className='grid grid-cols-1 gap-3 md:gap-4'>
            {map(sidePosts, (item, index) => (
              <Post key={index} width={"100%"} position='side' post={item} />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TopPost;
