import React from "react";
import { Button, Result, Table, Tag, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { getOrderStatus } from "../utils";

const PaymentPage = () => {
  const { state } = useLocation();
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      responsive: ['md'],
      render: () => <Typography>1</Typography>,
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "documentNumber",
      align: "center",
      key: "documentNumber",
    },
    {
      title: "Ghi chú",
      dataIndex: "description",
      align: "center",
      key: "description",
      responsive: ['lg'],
      render: (value) => <Typography className='text-left'>{value}</Typography>,
    },
    {
      title: "Tổng cộng",
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "right",
      render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' className='text-sm md:text-base' />,
    },
    {
      title: "Chiết khấu",
      dataIndex: "productDiscountAmount",
      key: "productDiscountAmount",
      align: "right",
      responsive: ['md'],
    },
    {
      title: "Mã giảm giá",
      dataIndex: "promotionDiscount",
      key: "promotionDiscount",
      align: "right",
      responsive: ['lg'],
    },
    {
      title: "Phí ship",
      dataIndex: "shippingFee",
      key: "shippingFee",
      align: "right",
      responsive: ['md'],
    },
    {
      title: "Thanh toán",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      align: "right",
      render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' className='text-sm md:text-base' />,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (value) => <Tag color='orange' className='text-xs md:text-sm'>{getOrderStatus(value)?.label}</Tag>,
    },
  ];

  return (
    <div className='wide px-2 md:px-4 lg:px-6'>
      <Result
        status='success'
        title={<span className='text-lg md:text-xl lg:text-2xl'>Đặt hàng thành công!</span>}
        subTitle={<span className='text-sm md:text-base'>Chúng tôi sẽ liên lạc và giao hàng sớm cho bạn, hãy lưu lại mã đơn hàng để kiểm tra nếu cần!</span>}
        extra={[
          <Table
            columns={columns}
            dataSource={[state]}
            bordered
            scroll={{ x: true }}
            className='overflow-x-auto'
            size={window.innerWidth < 768 ? 'small' : 'middle'}
          />,
          <div className='flex flex-col md:flex-row gap-2 md:gap-4 justify-center mt-4'>
            <Button type='primary' key='console'>
              <Link to='/'>Trang chủ</Link>
            </Button>
            <Button key='buy'>
              <Link to='/orders'>Xem đơn hàng</Link>
            </Button>
          </div>
        ]}
      />
    </div>
  );
};

export default PaymentPage;
