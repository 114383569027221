import { DeleteOutlined, DownSquareOutlined, FundOutlined, SortAscendingOutlined, UpSquareOutlined } from "@ant-design/icons";
import { Button, Pagination, Space, Spin, Tag, Typography } from "antd";
import { get, map, orderBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { Link } from "react-router-dom";
import { getAllProduct } from "../../services/productServices";
import Product from "../Product";

const ProductList = (props) => {
  const params = get(props, "params", null);
  const placement = get(props, "placement", "home");
  const [pagination, setPagination] = useState({ page: props?.page, size: props?.size });
  const [sort, setSort] = useState(null);
  const [active, setActive] = useState(null);

  const {
    data: productList,
    isFetching,
    refetch,
  } = useQuery("getAllProductList", {
    queryFn: async () => {
      const data = await getAllProduct({ params: { ...params, ...pagination } });
      return data;
    },
    optimisticResults: true,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    setPagination((prev) => ({ ...prev, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const products = useMemo(() => {
    if (!isFetching && productList && productList.data.length >= 0) {
      switch (sort) {
        case "name":
          return {
            ...productList,
            data: orderBy(productList?.data, ["name"], ["asc"]),
          };
        case "sold":
          return {
            ...productList,
            data: orderBy(productList?.data, ["sold"], ["desc"]),
          };
        case "low":
          return {
            ...productList,
            data: orderBy(productList?.data, ["discountPrice"], ["desc"]),
          };
        case "hight":
          return {
            ...productList,
            data: orderBy(productList?.data, ["discountPrice"], ["asc"]),
          };
        default:
          return productList;
      }
    }
  }, [isFetching, productList, sort]);

  const handleOnFilter = (value) => {
    setActive(value);
    setSort(value);
  };

  return (
    <div className={`container wide mx-auto ${placement === 'search' ? '' : 'px-3 sm:px-4 lg:px-6 xl:px-0'}`}>
      {placement === "search" ? (
        <>
          <div className='w-full flex flex-col'>
            <div className='flex flex-col gap-3 md:flex-row md:items-center justify-between mb-4'>
              <Typography className='text-left text-sm md:text-base'>
                {products?.total ? `${products.total} sản phẩm` : 'Danh sách sản phẩm'}
              </Typography>

              <div className='flex overflow-x-auto md:hidden gap-2 pb-2'>
                <Button
                  size="small"
                  type={active === "name" ? "primary" : "default"}
                  onClick={() => handleOnFilter("name")}
                  className='flex items-center'
                >
                  <SortAscendingOutlined className='mr-1' /> Tên
                </Button>
                <Button
                  size="small"
                  type={active === "sold" ? "primary" : "default"}
                  onClick={() => handleOnFilter("sold")}
                  className='flex items-center'
                >
                  <FundOutlined className='mr-1' /> Bán chạy
                </Button>
                <Button
                  size="small"
                  type={active === "hight" ? "primary" : "default"}
                  onClick={() => handleOnFilter("hight")}
                  className='flex items-center'
                >
                  <UpSquareOutlined className='mr-1' /> Giá tăng
                </Button>
                <Button
                  size="small"
                  type={active === "low" ? "primary" : "default"}
                  onClick={() => handleOnFilter("low")}
                  className='flex items-center'
                >
                  <DownSquareOutlined className='mr-1' /> Giá giảm
                </Button>
                <Button
                  size="small"
                  type='default'
                  onClick={() => handleOnFilter(null)}
                  className='flex items-center'
                >
                  <DeleteOutlined />
                </Button>
              </div>

              <Space className='hidden md:flex' wrap>
                <Typography>Sắp xếp theo</Typography>
                <Button
                  type={active === "name" ? "primary" : "default"}
                  onClick={() => handleOnFilter("name")}
                  className='flex items-center'
                >
                  <SortAscendingOutlined className='mr-1' /> Tên sản phẩm
                </Button>
                <Button
                  type={active === "sold" ? "primary" : "default"}
                  onClick={() => handleOnFilter("sold")}
                  className='flex items-center'
                >
                  <FundOutlined className='mr-1' /> Bán chạy
                </Button>
                <Button
                  type={active === "hight" ? "primary" : "default"}
                  onClick={() => handleOnFilter("hight")}
                  className='flex items-center'
                >
                  <UpSquareOutlined className='mr-1' /> Giá tăng
                </Button>
                <Button
                  type={active === "low" ? "primary" : "default"}
                  onClick={() => handleOnFilter("low")}
                  className='flex items-center'
                >
                  <DownSquareOutlined className='mr-1' /> Giá giảm
                </Button>
                <Button
                  type='default'
                  onClick={() => handleOnFilter(null)}
                  className='flex items-center'
                >
                  <DeleteOutlined className='mr-1' /> Xóa
                </Button>
              </Space>
            </div>

            <div className='flex justify-end mb-4'>
              <Pagination
                total={products?.total ?? 0}
                onChange={(page, size) => setPagination({ page, size })}
                pageSize={pagination?.size}
                current={pagination?.page}
                showSizeChanger
                size="default"
                pageSizeOptions={[12, 24, 48, 60, 72]}
                showTotal={false}
              />
            </div>

            <div className="relative">
              <Spin spinning={isFetching} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10" />
              <div className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-3 ${isFetching ? 'opacity-40' : ''}`}>
                {map(products?.data ?? [], (product) => (
                  <Product product={product} key={product.id} />
                ))}
              </div>
            </div>
          </div>

          <Pagination
            className='mt-6 md:mt-8 pb-4 text-center'
            total={products?.total ?? 0}
            onChange={(page, size) => setPagination({ page, size })}
            showTotal={(total) => `Tổng ${total} sản phẩm`}
            pageSize={pagination?.size}
            current={pagination?.page}
            showSizeChanger
            size="default"
            pageSizeOptions={[12, 24, 48, 60, 72]}
          />
        </>
      ) : (
        <div className='w-full flex flex-col'>
          <div className="mt-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 md:gap-3">
            {map(products?.data ?? [], (product) => (
              <Product product={product} key={product.id} />
            ))}
            <Spin spinning={isFetching} fullscreen tip='Đang tải dữ liệu...' />
          </div>
          <Link to={"/tim-kiem"} className='text-center mt-6 md:mt-10'>
            <Tag className='text-gray-500 font-medium px-2 py-1 rounded-sm text-sm md:text-base'>
              XEM THÊM SẢN PHẨM
            </Tag>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProductList;
