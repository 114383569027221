import { ShoppingCartOutlined, StarFilled } from "@ant-design/icons";
import { Divider, Space, Tag, Tooltip } from "antd";
import React from "react";
import { NumericFormat } from "react-number-format";

import { Link } from "react-router-dom";
import { formatNumberToString } from "../../utils";

const Product = (props) => {
  const { product, banner } = props;
  return (
    <Link
      className='relative p-2 flex flex-col h-full rounded-xl bg-white duration-200 hover:shadow-lg'
      to={`/${product?.category}/${product?.url}`}
      href={`/${product?.category}/${product?.url}`}
    >
      {(product?.discountPercent > 0 && product?.discountPrice) && (
        <div className='bg-orange-600 absolute right-0 top-0 rounded-tr-xl rounded-bl-xl text-xs md:text-sm py-1 px-1.5 md:px-2 text-white font-medium z-10'>
          {`- ${product?.discountPercent}%`}
        </div>
      )}

      <div className='w-full pb-[100%] relative mb-2'>
        <div className='absolute inset-0 flex items-center justify-center'>
          <img
            src={product?.images?.[0]}
            alt={product?.name}
            className='max-h-full max-w-full object-contain'
          />
        </div>
      </div>

      <Space direction='vertical' className='text-left px-1 md:px-2 !min-w-full !gap-1 flex-1'>
        <div className='flex justify-between items-end my-1'>
          <h4 className='font-bold !text-orange-600 text-sm md:text-base'>
            <NumericFormat
              value={product?.discountPrice ?? product?.salePrice}
              displayType='text'
              thousandSeparator='.'
              decimalSeparator=','
              suffix=' ₫'
            />
          </h4>
          {(product?.discountPrice || product?.discountPercent !== 0) && (
            <del className='text-gray-400 text-[12px] md:text-sm ml-1'>
              <NumericFormat
                value={product?.salePrice}
                displayType='text'
                thousandSeparator='.'
                decimalSeparator=','
                suffix=' ₫'
              />
            </del>
          )}
        </div>

        <Tag color='#50a199' className='font-bold text-[12px] md:text-sm !m-0 !px-1 md:!px-2'>
          {product?.brand}
        </Tag>

        <Tooltip title={product?.name} placement='bottom' color='rgba(0,0,0,0.7)'>
          <h1 className='text-ellipsis line-clamp-2 mb-1 min-h-[28px] md:min-h-[36px] lg:min-h-[44px] font-semibold text-gray-600 text-sm lg:text-base'>
            {product?.name}
          </h1>
        </Tooltip>

        <div className='h-[1px] bg-transparent border-b border-divider border-solid md:border-dashed'></div>

        <div className='flex items-center justify-between w-full text-[12px] md:text-sm'>
          <div className='flex items-center shrink-0'>
            <Space size={1} className='shrink-0'>
              <StarFilled className='text-yellow-500 text-[12px] md:text-sm' />
              <StarFilled className='text-yellow-500 text-[12px] md:text-sm' />
              <StarFilled className='text-yellow-500 text-[12px] md:text-sm' />
              <StarFilled className='text-yellow-500 text-[12px] md:text-sm' />
              <StarFilled className='text-yellow-500 text-[12px] md:text-sm' />
            </Space>
            <Divider type='vertical' className='mx-1 md:mx-2 !h-3' />
            <span className='font-[400] shrink-0 text-gray-600'>
              {formatNumberToString(product?.heart)}
            </span>
          </div>

          <div className='flex items-center shrink-0 ml-1'>
            <ShoppingCartOutlined className='text-[12px] md:text-sm text-gray-600' />
            <span className='ml-1 font-[400] text-gray-600'>
              {formatNumberToString(product?.sold)}
            </span>
          </div>
        </div>

        {banner && (
          <div className='relative h-[14px] md:h-[16px] overflow-hidden rounded-xl bg-red-500/50 px-1 md:px-2 py-[2px] text-center text-[8px] md:text-[10px] font-semibold text-white mt-1'>
            <div
              className='absolute left-0 top-0 z-[1] h-full rounded-xl bg-red-500'
              style={{ width: "95%" }}
            ></div>
            <div className='absolute bottom-0 left-0 right-0 top-0 z-[2] flex items-center justify-center'>
              Đang bán chạy
            </div>
          </div>
        )}
      </Space>
    </Link>
  );
};

export default Product;
