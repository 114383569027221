import { EditOutlined, HomeOutlined, ScheduleOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Card, Col, Input, InputNumber, List, Modal, Select, Table, Tag, Tooltip, Typography, Form, Space } from "antd";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { formatterNumber, getOrderStatus, parserNumber } from "../utils";
import { useQuery } from "react-query";
import { getSaleOrder } from "../services/saleOrderService";
import { NumericFormat } from "react-number-format";
import { findIndex, map } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";

const OrderPage = () => {
  const { currentUser } = useSelector((state) => state?.authReducer?.login);

  const [open, setOpen] = useState(false);

  const initialValuesRef = useRef({});

  const { data: saleOrderList, isFetching } = useQuery({
    queryKey: "getSaleOrder",
    queryFn: async () => {
      const data = await getSaleOrder({ params: { userId: currentUser?._id } });
      return data;
    },
    refetchOnWindowFocus: false,
    enabled: true,
  });

  useEffect(() => {
    document.title = "Lịch sử đơn hàng";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnOpenModal = (record) => {
    initialValuesRef.current = record;
    setOpen(true);
  };

  const handleOnCloseModal = () => {
    initialValuesRef.current = {};
    setOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        title: "STT",
        dataIndex: "_id",
        key: "_id",
        align: "center",
        width: 60,
        render: (value) => <Typography className='text-sm text-primary'>{findIndex(saleOrderList ?? [], (item) => item?._id === value) + 1}</Typography>,
      },
      {
        title: "Ngày",
        dataIndex: "createAt",
        key: "createAt",
        align: "center",
        width: 120,
        sorter: (a, b) => moment(a?.createAt).valueOf() - moment(b?.createAt).valueOf(),
        render: (value) => <Typography>{moment(value).format("DD/MM/YYYY")}</Typography>,
      },
      {
        title: "Mã đơn hàng",
        dataIndex: "documentNumber",
        align: "center",
        key: "documentNumber",
        width: 350,
        render: (value, record) => (
          <Typography className='text-left text-primary cursor-pointer' onClick={() => handleOnOpenModal(record)}>
            {value}
          </Typography>
        ),
        filters: map(saleOrderList, (item) => ({ value: item?.documentNumber, text: item?.documentNumber })),
        onFilter: (value, record) => record.documentNumber.startsWith(value),
        filterSearch: true,
      },
      {
        title: "Ghi chú",
        dataIndex: "description",
        align: "center",
        key: "description",
        width: 320,
        filters: map(saleOrderList, (item) => ({ value: item?.description, text: item?.description })),
        onFilter: (value, record) => record.description.startsWith(value),
        filterSearch: true,
        render: (value) => <Typography className='text-left'>{value}</Typography>,
      },
      {
        title: "Thanh toán",
        dataIndex: "paymentAmount",
        key: "paymentAmount",
        align: "right",
        width: 150,
        sorter: (a, b) => a?.paymentAmount - b?.paymentAmount,
        render: (value) => <NumericFormat value={value} displayType='text' thousandSeparator='.' decimalSeparator=',' suffix=' ₫' />,
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 180,
        render: (value) => (
          <Tag className='min-w-[120px] text-center' color={getOrderStatus(value)?.color}>
            {getOrderStatus(value)?.label}
          </Tag>
        ),
      },
    ],
    [saleOrderList]
  );

  return (
    <Fragment>
      <Breadcrumb
        className='wide mx-auto mb-4 px-4 md:px-0'
        items={[
          {
            href: "/",
            title: (
              <>
                <HomeOutlined />
                <span>Trang chủ</span>
              </>
            ),
          },
          {
            href: "/account",
            title: <span>Thông tin cá nhân</span>,
          },
          {
            title: <span>Đơn hàng</span>,
          },
        ]}
      />
      <div className='wide mx-auto rounded-lg bg-white py-6 px-4 flex flex-col lg:flex-row gap-3'>
        <Card
          title='Lịch sử đơn hàng'
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            overflowX: "auto",
          }}
        >
          <Table
            loading={isFetching}
            columns={columns}
            dataSource={saleOrderList}
            bordered
            scroll={{ x: 'max-content' }}
          />
        </Card>

        <Modal
          open={open}
          title={
            <Space>
              <pan>Thông tin đơn hàng</pan>
              {
                <Tag className='min-w-[120px] text-center' color={getOrderStatus(Number(initialValuesRef.current.status))?.color}>
                  {getOrderStatus(Number(initialValuesRef.current.status))?.label}
                </Tag>
              }
            </Space>
          }
          onCancel={handleOnCloseModal}
          keyboard
          maskClosable
          destroyOnClose={true}
          focusTriggerAfterClose={false}
          width={window.innerWidth > 768 ? 900 : '95%'}
          centered
          footer={null}
        >
          <Form initialValues={initialValuesRef.current} spellCheck={false} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} disabled>
            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='flex-1 lg:flex-[0.5] border p-3 rounded-lg border-gray-100'>
                <Col span={24}>
                  <Form.Item name='documentNumber' label='Mã đơn hàng'>
                    <Input placeholder='Mã đơn hàng' allowClear={true} prefix={<ScheduleOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='totalAmount' label='Tổng cộng'>
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Tổng cộng'
                      controls={false}
                      allowClear={true}
                      min={0}
                      prefix={<EditOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='productDiscountAmount' label='Chiết khấu'>
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Chiết khấu'
                      controls={false}
                      allowClear={true}
                      min={0}
                      prefix={<EditOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='promotionDiscount' label='Mã giảm giá'>
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Mã giảm giá'
                      controls={false}
                      allowClear={true}
                      min={0}
                      prefix={<EditOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='shippingFee' label='Phí ship'>
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Phí ship'
                      controls={false}
                      allowClear={true}
                      min={0}
                      prefix={<EditOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='paymentAmount' label='Thanh toán'>
                    <InputNumber
                      className='w-full'
                      formatter={formatterNumber}
                      parser={parserNumber}
                      placeholder='Thanh toán'
                      controls={false}
                      allowClear={true}
                      min={0}
                      prefix={<EditOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='status' label='Trạng thái'>
                    <Select
                      options={getOrderStatus()}
                      placeholder='Trạng thái'
                      allowClear={true}
                      prefix={<ScheduleOutlined className='mr-2 text-gray-500' />}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name='description' label='Ghi chú'>
                    <Input.TextArea placeholder='Ghi chú' allowClear={true} prefix={<ScheduleOutlined className='mr-2 text-gray-500' />} />
                  </Form.Item>
                </Col>
                <Col span={0} hidden={true}>
                  <Form.Item name='_id' label='' hidden={true}>
                    <Input placeholder='' allowClear={true} />
                  </Form.Item>
                </Col>
              </div>

              <Card title='Chi tiết sản phẩm' className='flex-1 lg:flex-[0.5]'>
                <List
                  pagination={{
                    position: "bottom",
                    align: "end",
                  }}
                  dataSource={initialValuesRef.current?.details}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item?.images?.[0]} size={64} />}
                        title={
                          <Tooltip title={item?.name}>
                            <Link className='line-clamp-2' to={`/${item?.category}/${item?.url}`}>
                              {item?.name}
                            </Link>
                          </Tooltip>
                        }
                        description={`${item?.quantity} x ${formatterNumber(item?.discountPrice)}đ = ${formatterNumber(item?.quantity * item?.discountPrice)}đ`}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </div>
          </Form>
        </Modal>
      </div>
    </Fragment>
  );
};

export default OrderPage;
